import React from "react"
import { JoinCivicAllianceButton } from "../components/Buttons";
import Layout from "../components/Layout"
import Section from "../components/Section";
import {
  BorderDiv,
  HeaderH1,
  HeaderH3,
  SectionH2,
  SectionP,
} from "../helpers/styledComponents";


const headerContent = (
  <>
    <HeaderH1>2020 Census</HeaderH1>
    <HeaderH3 style={{maxWidth: "900px", margin: "auto"}}>The Civic Alliance helped businesses work toward a complete and accurate count in 2020 because we know how important Census data is for our companies, our communities, and our country.</HeaderH3>
  </>
)

export default () => {
  return (
    <Layout 
      mode="dark" 
      headerContent={headerContent} 
      title="Census | Civic Alliance"
    >
      <Section mode="light" extraMargin>
        <SectionH2>Why a Complete and<br/>Accurate Census was Critical</SectionH2>
        <SectionP center>The 2020 Decennial Census was a constitutionally mandated once-a-decade count of our nation.</SectionP>
        <SectionP center>It was critical that we achieved a complete and accurate count of everyone in the United States because the results determined how many representatives in Congress each state would get -- and how almost 900 billion federal dollars would be distributed each year for the next decade.</SectionP>
        <SectionP center>But it wasn’t just money and power that made the Census important. With 2020’s Census being the first to be conducted primarily online, America’s business community was more important than ever to help get the word out and ensure a complete count.</SectionP>
      </Section>
      <Section mode="dark" larger style={{ borderBottom: "1px solid "}}>
        <SectionH2>How the Civic Alliance<br/>Supported Its Members on the Census</SectionH2>
        <BorderDiv mode="dark" style={{ marginBottom: "2em" }}>
          <SectionP>Companies played a unique role in Get Out the Count efforts by engaging their employees, their customers, and their audiences. The Civic Alliance encouraged all companies to work toward a complete and accurate count by leveraging their brands and infrastructure to Get Out the Count.</SectionP>
        </BorderDiv>
        <BorderDiv mode="dark" style={{ marginBottom: "2em" }}>
          <SectionP>The Civic Alliance provided member companies with best practices and toolkits to help them encourage their employees, customers, and communities to participate in the Census.</SectionP>
        </BorderDiv>
        <JoinCivicAllianceButton mode="light" center />
      </Section>
    </Layout>
  );
}